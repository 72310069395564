<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="addNewIncidentMessageForm"
        ref="addNewIncidentMessageForm"
        v-model="isFormValid"
        @submit.prevent="submitNewIncidentMessageForm"
      >
        <v-row class="mt-4">
          <v-col cols="12">
            <v-menu
              v-model="showDatePicker"
              v-bind="attributes.menu"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  :value="formattedDate"
                  :rules="showDatePicker ? [] : [rules.required]"
                  label="Data"
                  placeholder="Wybierz datę"
                  v-bind="{...attrs, ...attributes.textField}"
                  v-on="on"
                >
                  <template #prepend-inner>
                    <Icon
                      name="date"
                      size="small"
                      class="mr-2"
                    />
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                v-model="incident.reportedAt"
                v-bind="attributes.datePicker"
                :rules="showDatePicker ? [] : [rules.required]"
                :min="minDate"
                @input="showDatePicker = false"
              />
            </v-menu>
          </v-col>

          <v-col cols="12">
            <v-text-field
              outlined
              label="Osoba zgłaszająca"
              v-model="incident.contactName"
              :rules="[rules.required]"
              placeholder="Podaj imię i nazwisko osoby zgłaszającej"
            />
          </v-col>

          <v-col cols="12">
            <v-textarea
              outlined
              :rows="3"
              auto-grow
              :rules="[rules.required]"
              label="Wiadomość klienta"
              v-model="incident.text"
              placeholder="Wpisz wiadomość klienta"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        type="submit"
        form="addNewIncidentMessageForm"
      >
        Dodaj wiadomość
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import DateTime from 'luxon/src/datetime'
import rules from '../../utils/validators'
import attributes from '../../const/datePickerAttrributes'
import { dateStringFormat } from '../../utils'
import updateFormValue from '../../mixins/updateFormValue.vue'
import DialogFormWrapper from './Partials/DialogFormWrapper'
import afterFormSubmitted from '../../mixins/afterFormSubmitted'

export default {
  components: {
    DialogFormWrapper
  },
  mixins: [updateFormValue, afterFormSubmitted],
  data () {
    return {
      rules,
      attributes,
      isFormValid: true,
      showDatePicker: false,
      incident: {
        clientId: null,
        reportedAt: new Date().toISOString().substr(0, 10),
        text: null
      }
    }
  },
  computed: {
    ...mapState({
      filters: state => state.core.filters,
      isProcessing: state => state.incidents.isProcessing,
      associatedIncident: state => state.layout.dialog.item
    }),
    formattedDate () {
      if (this.incident.reportedAt) {
        return dateStringFormat(this.incident.reportedAt)
      }
      return ''
    },
    minDate () {
      return DateTime.utc().toISODate()
    }
  },
  methods: {
    ...mapActions({
      addNewIncident: 'incidents/addNewItem'
    }),
    submitNewIncidentMessageForm () {
      this.$refs.addNewIncidentMessageForm.validate()
      const endpoint = `incidents/${this.associatedIncident.id}/messages`

      if (this.isFormValid) {
        this.addNewIncident({ params: this.incident, endpoint })
          .then(() => {
            this.afterFormSubmitted('Dodano wiadomość')
          })
      }
    }
  }
}
</script>
